import { createClient } from '@supabase/supabase-js';

// Supabase client, replaces axios to make requests to the database more easily.
// This client would basically replace the genericApi function in genericApi.js
// This is the most used api function anyway due to the overhead of creatign a new api for each table
// Would just do search and replace to replace table names if needed
// const supabaseClient = createClient(BASE_URL, SUPABASE_ANON_KEY);

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
