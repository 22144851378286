export const Items = [
    {
        href: '/cpd/people',
        title: 'People'
    },
    {
        href: '/cpd/meetings',
        title: 'Meetings'
    },
    {
        href: '/cpd/companies',
        title: 'Companies'
    },
    {
        href: '/cpd/seeking',
        title: 'Seeking'
    },
    {
        href: '/cpd/investments',
        title: 'All Investments'
    },
    {
        href: '/cpd/glossary',
        title: 'Glossary'
    }
];
