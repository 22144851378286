import { useState } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    AppBar,
    IconButton,
    Toolbar,
    Tabs,
    Tab,
    Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from './Logo';
import { supabase } from '../utils/supabaseClient';
import { Items } from '../utils/constants/nav';

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
    const [notifications] = useState([]);

    const items = Items;
    const location = useLocation();

    return (
        <AppBar elevation={0} {...rest}>
            <Toolbar>
                <RouterLink to="/">
                    <Logo />
                </RouterLink>

                <Tabs
                    aria-label="Navigation Tabs"
                    indicatorColor="primary"
                    textColor="inherit"
                    value={location.pathname.split('/').slice(0, 3).join('/')}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                >
                    {items.map((item) => (
                        <Tab
                            label={item.title}
                            component={RouterLink}
                            to={item.href}
                            key={item.href}
                            value={item.href}
                        />
                    ))}
                </Tabs>
                <IconButton
                    color="inherit"
                    onClick={onMobileNavOpen}
                    sx={{ display: { md: 'none', xs: 'block' } }}
                >
                    <MenuIcon />
                </IconButton>
                <Button
                    color="inherit"
                    variant="outlined"
                    sx={{ ml: 2 }}
                    style={{ marginLeft: 'auto' }}
                    onClick={async () => {
                        const { error } = await supabase.auth.signOut();

                        if (error) {
                            console.log('Error logging out:', error.message);
                        } else {
                            window.location.reload();
                        }
                    }}
                >
                    Logout
                </Button>
            </Toolbar>
        </AppBar>
    );
};

DashboardNavbar.propTypes = {
    onMobileNavOpen: PropTypes.func
};

export default DashboardNavbar;
