/*
provider.js - Defines how axios or any api library should connect with the database and connect our response data back to any connected file or component.
*/

// provider.js

import axios from 'axios';
import { supabase } from '../../utils/supabaseClient';
import { handleResponse, handleError } from './response';
// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = process.env.REACT_APP_API_ENDPOINT + '/rest/v1';
const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY;

// Add a request interceptor, this will run before any request is sent
axios.interceptors.request.use(
    (config) => {
        // getSession is done async so we have to return a promise
        // We are returning a promise. It is then the responsibility of the caller to handle the promise.
        // Since use expects a promise, this is ok.
        return supabase.auth.getSession().then((session) => {
            // Set the Authorization header
            const authHeader = `Bearer ${session.data.session.access_token}`;
            config.headers.Authorization = authHeader;
            config.headers.apikey = SUPABASE_ANON_KEY;
            return config;
        });
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    }
);

/** @param {string} resource */
const getAll = (resource) => {
    return axios
        .get(`${BASE_URL}/${resource}`)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const getSingle = (resource, id) => {
    return axios
        .get(`${BASE_URL}/${resource}?id=eq.${id}`)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const post = (resource, model) => {
    console.log(`${BASE_URL}/${resource}`, model);
    return axios
        .post(`${BASE_URL}/${resource}`, model)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
//! Not checked
const put = (resource, model) => {
    return axios
        .put(`${BASE_URL}/${resource}`, model)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
/** @param {object} id */
const patch = (resource, model, id) => {
    return axios
        .patch(`${BASE_URL}/${resource}?id=eq.${id}`, model)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
//! Not checked
const remove = (resource, id) => {
    return axios
        .delete(`${BASE_URL}/${resource}?id=eq.${id}`)
        .then(handleResponse)
        .catch(handleError);
};

const complexRemove = (complexQuery) => {
    //! Not checked
    return axios
        .delete(`${BASE_URL}/${complexQuery}`)
        .then(handleResponse)
        .catch(handleError);
};

/**
 * used for complex queries, most commonly on relations
 * Not neccessarily just singles as well***
 * @param {*} complexQuery i.e employs?companies_id=eq.7&people_id=eq.101
 * @returns Response
 */
const complexGetSingle = (complexQuery) => {
    //! Not checked
    return axios
        .get(`${BASE_URL}/${complexQuery}`)
        .then(handleResponse)
        .catch(handleError);
};

/**
 * used for complex patches, most commonly on relations. Other put only specifies id.
 * @param {*} complexQuery i.e `employs?companies_id=eq.${id}&people_id=eq.${id2}`
 * @param {*} model the model to update, i.e the new table info
 * @returns Response
 */
const complexPutSingle = (complexQuery, model) => {
    //! Not checked
    return axios
        .put(`${BASE_URL}/${complexQuery}`, model)
        .then(handleResponse)
        .catch(handleError);
};

//! Probably need one for delete
//If add delete also need to change api-provider and api-core

export const apiProvider = {
    getAll,
    getSingle,
    post,
    put,
    patch,
    remove,
    complexGetSingle,
    complexPutSingle,
    complexRemove
};
