import { Card } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
//For api
import { Link } from 'react-router-dom';

//For search in datagrid
import { DataGrid } from '@material-ui/data-grid';

import * as React from 'react';

import QuickSearchToolbar from '../../utils/QuickSearchToolbar';
import Create from '../../companies/Company/createCompany';

import { CardHeader } from '@material-ui/core';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const CompaniesDataGrid = ({ companies }) => {
    const columns = [
        {
            field: 'id',
            headerName: 'More',
            width: 75,
            // eslint-disable-next-line react/display-name
            renderCell: (params) => (
                <IconButton component={Link} to={`/cpd/companies/${params.id}`}>
                    <InfoIcon color="primary" />
                </IconButton>
            ),
            disableColumnFilter: true,
            disableColumnMenu: true,
            sortable: false
        },
        { field: 'ranking', headerName: 'Ranking', width: 150 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'specialty', headerName: 'Specialty', width: 160 },
        { field: 'region', headerName: 'Region', width: 150 },
        { field: 'company_type', headerName: 'Type', width: 150 },
        { field: 'size', headerName: 'Size ($m)', width: 150 },
        { field: 'company_state', headerName: 'State', width: 250 },
        { field: 'company_city', headerName: 'City', width: 250 },
        { field: 'company_street', headerName: 'Street', width: 250 },
        { field: 'description', headerName: 'Description', width: 160 },
        { field: 'other', headerName: 'Other', width: 250 }
    ];

    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState(companies);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = companies.filter((row) => {
            return Object.keys(row).some((field) => {
                if (row[field] == null) {
                    return false;
                } else {
                    return searchRegex.test(row[field].toString());
                }
            });
        });
        setRows(filteredRows);
    };

    React.useEffect(() => {
        setRows(companies);
    }, [companies]);

    return (
        <Card style={{ height: 800, width: '100%' }}>
            {/* <CardHeader title={'Companies'} action={<Create />} />
            <DataGrid
                columns={columns}
                rows={rows}
                pageSize={10}
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch('')
                    }
                }}
            /> */}

            {/* Add card header and datagrid, with padding to ensure datagrid paginiation shows */}
            <CardHeader title={'Companies'} action={<Create />} />
            <div style={{ height: 700 }}>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    pageSize={10}
                    components={{ Toolbar: QuickSearchToolbar }}
                    componentsProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event) =>
                                requestSearch(event.target.value),
                            clearSearch: () => requestSearch('')
                        }
                    }}
                />
            </div>
        </Card>
    );
};

export default CompaniesDataGrid;
