import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
// import './mixins/chartjs';
import theme from './theme';
import routes from './routes';
import { useState, useEffect } from 'react';
import { supabase } from './utils/supabaseClient';
import Loading from './components/utils/Loading';

const App = () => {
    const [session, setSession] = useState(null);

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            console.log(session);

            // If session is null, the user is not logged in, set to false
            if (session == null) {
                setSession(false);
            } else {
                setSession(session);
            }
        });

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });
    }, []);

    const routing = useRoutes(routes(session));

    // Null for loading, false for not logged in, and session for logged in
    if (session == null) {
        return <Loading />;
    }
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {routing}
        </ThemeProvider>
    );
};

export default App;
