import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, CardHeader, Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/styles';
import genericApi from '../../../../apis/genericApi';
import CurrentInvestmentsForm from './CurrentInvestmentsForm';
import { DataGrid } from '@mui/x-data-grid';

const useStyles = makeStyles(() => ({
    root: {},
    actions: {
        justifyContent: 'flex-end'
    }
}));

const CurrentInvestments = ({ id, className, type, ...rest }) => {
    const classes = useStyles();
    const [investments, setInvested] = useState([]);

    let isCompanyPage = type == 'company' ? true : false;

    function getInvestments(id) {
        if (id != null) {
            id = parseInt(id);

            if (isCompanyPage) {
                genericApi('')
                    .complexGetSingle(
                        `/currentinvestments?companies_id=eq.${id}`
                    )
                    .then((res) => {
                        setInvested(res);
                    });
            } else {
                genericApi('')
                    .complexGetSingle(
                        `/currentinvestments?investments_id=eq.${id}`
                    )
                    .then((res) => {
                        setInvested(res);
                    });
            }
        }
    }

    function createInvestments(formInfo) {
        delete formInfo.company;
        delete formInfo.investment;
        //Update currentInvestments with the new info
        genericApi('invested')
            .post(formInfo)
            .then((res) => {
                console.log(res);
                window.location.reload();
            });
    }

    function editInvestments(formInfo) {
        console.log(formInfo);
        delete formInfo.company_name;
        delete formInfo.investment_name;
        delete formInfo.company;

        const complexQuery = `invested?companies_id=eq.${formInfo.companies_id}&investments_id=eq.${formInfo.investments_id}`;
        console.log(formInfo);
        console.log(complexQuery);
        genericApi('invested')
            .complexPutSingle(complexQuery, formInfo)
            .then((res) => {
                console.log(res);
                window.location.reload();
            });
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        getInvestments(id);
    }, [id]);

    const columns = [
        {
            field: 'companies_id',
            headerName: '',
            width: 90,
            // eslint-disable-next-line react/display-name
            renderCell: (params) => (
                <CurrentInvestmentsForm
                    type="edit"
                    handleSeeking={editInvestments}
                    info={params.row}
                />
            )
        },
        {
            field: 'companies_id_more',
            headerName: 'More',
            width: 70,
            // eslint-disable-next-line react/display-name
            renderCell: (params) => (
                <IconButton
                    component={Link}
                    to={`/cpd/companies/${params.row.companies_id}`}
                >
                    <InfoIcon color="primary" />
                </IconButton>
            ),
            disableColumnFilter: true,
            disableColumnMenu: true,
            sortable: false
        },
        {
            field: 'investments_id',
            headerName: 'More',
            width: 70,
            // eslint-disable-next-line react/display-name
            renderCell: (params) => (
                <IconButton
                    component={Link}
                    to={`/cpd/investments/${params.row.investments_id}/show`} //! Should this be invested?
                >
                    <InfoIcon color="primary" />
                </IconButton>
            )
        },
        { field: 'investment_name', headerName: 'Investment Name', width: 160 },
        { field: 'company_name', headerName: 'Company Name', width: 160 },
        { field: 'date_entered', headerName: 'Date Entered', width: 160 },
        { field: 'amount', headerName: 'Amount', width: 160 },
        {
            field: 'description',
            headerName: 'Inv. Comment',
            width: 150,
            flex: 1
        }
    ];

    const columnsVisibility = {
        investment_name: isCompanyPage,
        company_name: !isCompanyPage,
        investments_id: isCompanyPage, //This is actually the more column
        companies_id_more: !isCompanyPage
    };

    function getTitle() {
        if (isCompanyPage) {
            return 'Current Investments';
        } else {
            return 'Current Investors';
        }
    }

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader
                title={getTitle()}
                action={
                    <CurrentInvestmentsForm
                        type="create"
                        handleSeeking={createInvestments}
                    />
                }
            />
            <Divider />
            <PerfectScrollbar>
                <DataGrid
                    columns={columns}
                    columnVisibilityModel={columnsVisibility}
                    rows={investments}
                    pageSize={10}
                    //This should really be (row) => row.companies_id or even better the whole primary key from the db
                    getRowId={(row) =>
                        row.investments_id.toString() +
                        row.companies_id.toString()
                    }
                    //checkboxSelection
                    // components={{
                    //     Toolbar: GridToolbar
                    // }}
                />
            </PerfectScrollbar>
        </Card>
    );
};

export default CurrentInvestments;
