//Given meetings will show them nicely

import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useState } from 'react';
import HandleMeeting from './HandleMeeting';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2)
    },
    metadata: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary
    },
    notesarea: {
        paddingTop: 16,
        paddingLeft: 14,
        paddingRight: 60,
        paddingBottom: 14,
        lineHeight: 1.3
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(1)
    },
    cancel: {
        marginRight: theme.spacing(1)
    },
    content: {
        backgroundColor: '#edf3f0',
        padding: '0 1em',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'stretch',
        marginBottom: theme.spacing(1)
    },
    notes: {
        flex: 1
    },
    paragraph: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        lineHeight: 1.3,
        marginBottom: theme.spacing(2.4),
        marginTop: theme.spacing(0.7)
    },
    toolbar: {
        marginLeft: theme.spacing(2),
        visibility: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    }
}));

const MeetingsShow = ({ meeting, ...props }) => {
    const [isHover, setHover] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [meetingnotes, setmeetingnotes] = useState(meeting.notes + ''); // + '' to force to string

    console.log(meetingnotes);
    //const [meetings, setmeetings] = useState(meeting);
    const classes = useStyles();
    const editMeeting = props.editMeeting;

    return (
        <Container maxWidth={false}>
            <Typography component="span" variant="body1">
                <Grid container spacing={3}>
                    <Grid item>
                        <b>Relevant company:</b> {meeting.company_name}
                    </Grid>
                    <Grid item>
                        <b>Topic:</b> {meeting.topic}
                    </Grid>
                    <Grid item>
                        <b>Date:</b> {meeting.date_of}
                    </Grid>
                    <Grid item justify="flex-end">
                        <HandleMeeting
                            type="edit"
                            info={meeting}
                            handleMeeting={editMeeting}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item>
                        <b>Investment:</b> {meeting.investment_name}
                    </Grid>
                    <Grid item>
                        {/**Could do something like <Link to='cpd/person/id > </Link> */}
                        <b>Meeting participants:</b>{' '}
                        {meeting.person_name.map(
                            (person, index) => (index ? ', ' : '') + person
                        )}
                    </Grid>
                </Grid>
            </Typography>

            <div className={classes.content}>
                <div className={classes.notes}>
                    {meetingnotes.split('\n').map((paragraph, index) => (
                        <p className={classes.paragraph} key={index}>
                            {paragraph}
                        </p>
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default MeetingsShow;
