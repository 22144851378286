//! Only difference between this and edit will be that edit has values passed and uses patch in axios
import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import genericApi from '../../../apis/genericApi';
import { validFieldValues } from '../../../utils/constants/types';

//Test
import CompanyDetails from './CompanyForm';

const initialValue = {
    description: '',
    name: '',
    other: '',
    size: 0,
    specialty: '',
    type: 'Investors',
    company_street: '',
    company_city: '',
    company_state: '',
    region: validFieldValues.region[0],
    ranking: 1
};

//Have companytype and lookingfor in individual forms

export default function FormDialog({ companyInfo }) {
    console.log(companyInfo);
    const [open, setOpen] = React.useState(false);
    //Think would use useState(...props) maybe?

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setCompany(initialValue);
        setOpen(false);
    };

    const handleSubmit = () => {
        const companyType = {};
        console.log(company);
        //Prep insert into type table
        companyType.type = company.type;
        delete company.type;

        genericApi('companies')
            .post(company)
            .then((res) => {
                // Should really just setState but cbf
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    //Values lifted from form
    const [company, setCompany] = React.useState(initialValue);

    const handleChange = (event) => {
        console.log('Lifted');
        console.log(event.target.value);
        setCompany({
            ...company,
            [event.target.name]: event.target.value
        });
    };

    //Handles autocomplete tabs
    const handleAuto = (toAdd) => {
        setCompany({
            ...company,
            ...toAdd
        });
    };

    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
            >
                Add
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {/**Pass this props? Then it can be the same for edit and delete */}
                <CompanyDetails
                    handleChange={handleChange}
                    handleAuto={handleAuto}
                    values={company}
                />
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                    >
                        Save details
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
