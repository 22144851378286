import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';

import { Typography } from '@material-ui/core';
import { ranking } from './types';

import EditCompany from './editCompany';

type Company = {
    id: number;
    name: string;
    description: string;
    specialty: string;
    region: string;
    company_type: string;
    company_state: string;
    company_city: string;
    company_street: string;
    other: string;
    size: number;
    ranking: number;
};

type CompanyInfoProps = {
    company: Company;
    setCompany: any;
};

export default function CompanyInfo({ company, setCompany }: CompanyInfoProps) {
    return (
        <Fragment>
            <Typography component={'div'}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <h3>{company.name}</h3>
                        {company.id != null && (
                            <EditCompany
                                company={company}
                                setCompany={setCompany}
                            />
                        )}
                    </Grid>

                    <p>{company.company_type}</p>
                    <p>{company.region}</p>
                    <p>{ranking[company.ranking - 1]?.rankingText}</p>
                </Grid>
                <Grid item xs={4}>
                    <p>{company.size}</p>
                </Grid>
                <Grid item xs={4}>
                    <p>{company.specialty}</p>
                </Grid>
                <Grid item xs={4}>
                    <p>
                        {company.company_street +
                            ' ' +
                            company.company_city +
                            ' ' +
                            company.company_state}
                    </p>
                </Grid>
                <Grid item xs={12}>
                    {<p>{company.description}</p>}
                </Grid>
                <Grid>{<p>{company.other}</p>}</Grid>
            </Typography>
        </Fragment>
    );
}
