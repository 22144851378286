import { Card } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
//For api
import { Link } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import QuickSearchToolbar, { escapeRegExp } from '../utils/QuickSearchToolbar';
import React from 'react';

const InvestmentList = ({ investments }) => {
    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState(investments);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = investments.filter((row) => {
            return Object.keys(row).some((field) => {
                if (row[field] == null) {
                    return false;
                } else {
                    return searchRegex.test(row[field].toString());
                }
            });
        });
        setRows(filteredRows);
    };

    React.useEffect(() => {
        setRows(investments);
    }, [investments]);

    console.log(investments);
    const columns = [
        {
            field: 'id',
            headerName: 'More',
            width: 70,
            // eslint-disable-next-line react/display-name
            renderCell: (params) => (
                <IconButton
                    component={Link}
                    to={`/cpd/investments/${params.id}/show`}
                >
                    <InfoIcon color="primary" />
                </IconButton>
            ),
            disableColumnFilter: true,
            disableColumnMenu: true,
            sortable: false
        },
        { field: 'name', headerName: 'Investment Name', width: 200 },
        { field: 'target_returns', headerName: 'Returns', width: 150 },
        { field: 'rate', headerName: 'Rate', width: 120 },
        { field: 'deal_type', headerName: 'Deal type', width: 250 },
        { field: 'sector', headerName: 'Sector', width: 250 },
        { field: 'location', headerName: 'Location', width: 250 }
    ];

    return (
        <Card style={{ height: 800, width: '100%' }}>
            <DataGrid
                columns={columns}
                rows={rows}
                pageSize={10}
                components={{
                    Toolbar: QuickSearchToolbar
                }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch('')
                    }
                }}
                checkboxSelection
            />
        </Card>
    );
};

export default InvestmentList;
