import React from 'react';
import { Helmet } from 'react-helmet';

//Material UI
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import SeekingInvestments from '../components/companies/Related/SeekingInvestments/SeekingInvestments';
//CSS
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    fixedHeight: {
        height: 240
    },
    innerHeader: {
        margin: 'auto'
    }
}));

export default function Dashboard() {
    //Styling for material ui
    const classes = useStyles();
    return (
        <Container maxWidth={false} className={classes.container}>
            <Helmet>
                <title>Seeking | CPD</title>
            </Helmet>

            {/* Seeking investments */}
            <SeekingInvestments />
        </Container>
    );
}
