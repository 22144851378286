//View for seeking investments
import { Card } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import React, { useState, useEffect } from 'react';
import { CardHeader } from '@material-ui/core';
import SeekingForm from './SeekingForm';
import genericApi from '../../../../apis/genericApi';
import Button from '@material-ui/core/Button';

//For search in datagrid
//! Abstract with CustomerListResults
import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector
} from '@material-ui/data-grid';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import IconButton from '@material-ui/core/IconButton';

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport />
            <Box
                sx={{
                    p: 0.5,
                    pb: 0
                }}
            >
                <TextField
                    variant="standard"
                    value={props.value}
                    onChange={props.onChange}
                    placeholder="Search…"
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{
                                    visibility: props.value
                                        ? 'visible'
                                        : 'hidden'
                                }}
                                onClick={props.clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        )
                    }}
                    sx={{
                        width: {
                            xs: 1,
                            sm: 'auto'
                        },
                        m: (theme) => theme.spacing(1, 0.5, 1.5),
                        '& .MuiSvgIcon-root': {
                            mr: 0.5
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 1,
                            borderColor: 'divider'
                        }
                    }}
                />
            </Box>
        </GridToolbarContainer>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
};

const SeekingInvestmentDatagrid = ({ type = 'all', id }) => {
    const [initialInvestments, setInitialInvestments] = useState([]); // Just use this to have another copy of investments to search, defs not efficient
    const [investments, setInvested] = useState([]);

    function getInvestments() {
        console.log('Called again');
        if (type == 'specified') {
            console.log('INVESTMENT');
            if (id != null) {
                console.log(id);
                const complexQuery = `seekinginvestments?companies_id=eq.${id}`;
                genericApi()
                    .complexGetSingle(complexQuery)
                    .then((res) => {
                        setInvested(res);
                        console.log(res);
                    });
            }
        } else {
            genericApi('seekinginvestments')
                .getAll()
                .then((res) => {
                    setInvested(res);
                    setInitialInvestments(res);
                    console.log(res);
                });
        }
    }

    function createInvestments(formInfo) {
        delete formInfo.company;
        console.log(formInfo);

        //Update seekingInvestments with the new info
        genericApi('lookingfor')
            .post(formInfo)
            .then((res) => {
                console.log(res);
                // TODO Add error response handling like this anywhere there is window.location.reload
                // Basically, if there is 400 error (Something went wrong), alert appears, otherwise window is reloaded.
                if (res?.response?.status != 400) {
                    window.location.reload();
                }
            });
    }

    function editInvestments(formInfo) {
        delete formInfo.company;
        delete formInfo.name;

        genericApi('lookingfor')
            .patch(formInfo, formInfo.id)
            .then((res) => {
                console.log(res);
                window.location.reload();
            });
    }

    function deleteInvestments(id) {
        console.log(id);
        genericApi('lookingfor')
            .remove(id)
            .then((res) => {
                console.log(res);
                window.location.reload();
            });
    }

    const [searchText, setSearchText] = React.useState('');

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = initialInvestments.filter((row) => {
            return Object.keys(row).some((field) => {
                if (row[field] == null) {
                    return false;
                } else {
                    return searchRegex.test(row[field].toString());
                }
            });
        });
        setInvested(filteredRows);
    };

    // React.useEffect(() => {
    //     setRows(customers);
    //   }, [customers]);

    //When id passed is updated we want to recall
    useEffect(() => {
        getInvestments(id);
    }, [id]);

    const columns = [
        {
            field: 'companies_id',
            headerName: ' ',
            width: 90,
            // eslint-disable-next-line react/display-name
            renderCell: (params) => (
                <SeekingForm
                    type="edit"
                    handleSeeking={editInvestments}
                    info={params.row}
                />
            ),
            sortable: false
        },
        // Only show company name if type == 'specified'
        type != 'specified'
            ? {
                  field: 'name',
                  headerName: 'Company Name',
                  width: 200
              }
            : {},
        { field: 'type', headerName: 'Type', width: 130 },
        { field: 'sector', headerName: 'Sector', width: 130 },
        { field: 'size', headerName: 'Size $m', width: 140 },
        { field: 'start_date', headerName: 'Start Date', width: 160 },
        { field: 'return_min', headerName: 'Min return %', width: 180 },
        { field: 'return_max', headerName: 'Max return %', width: 180 },
        { field: 'is_looking', headerName: 'Is Looking', width: 160 },
        { field: 'finish_date', headerName: 'Finish Date', width: 160 },
        {
            field: 'id',
            headerName: ' ',
            width: 90,

            // eslint-disable-next-line react/display-name
            renderCell: (params) => {
                const onClickDelete = async () => {
                    deleteInvestments(params.row.id);
                };

                return (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onClickDelete}
                    >
                        DELETE
                    </Button>
                );
            },
            sortable: false
        }
    ];

    function getTitle() {
        if (type != 'specified') {
            return 'Companies are seeking';
        } else {
            return 'Seeking';
        }
    }

    function getAction() {
        if (type != 'specified') {
            return (
                <SeekingForm type="create" handleSeeking={createInvestments} />
            );
        }
    }

    function hideAction() {
        if (getAction() == null) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Card style={{ height: 800 }} sx={!hideAction() && { pb: 1 }}>
            {' '}
            {/**Extra padding is needed if action is showing*/}
            <CardHeader title={getTitle()} action={getAction()} />
            <DataGrid
                columns={columns}
                rows={investments}
                pageSize={10}
                // components={!hideAction() && {
                //     Toolbar: GridToolbar
                // }}
                components={!hideAction() && { Toolbar: QuickSearchToolbar }}
                componentsProps={
                    !hideAction() && {
                        toolbar: {
                            value: searchText,
                            onChange: (event) =>
                                requestSearch(event.target.value),
                            clearSearch: () => requestSearch('')
                        }
                    }
                }
                getRowId={(row) => row.id}
                checkboxSelection={!hideAction()}
                disableColumnMenu={hideAction()}
                disableColumnFilter={hideAction()}
                disableColumnSelector={hideAction()}
                disableSortable={hideAction()}
            />
        </Card>
    );
};

export default SeekingInvestmentDatagrid;
