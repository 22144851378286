import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Autocomplete
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

//Test
import complexGetSingle from '../../../apis/genericApi';
import { supabase } from '../../../utils/supabaseClient';

//Have json representation for each table we will insert via api
const initial_person = {
    prefix: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    business_city: 'Sydney',
    business_country: 'Australia',
    business_street: '',
    company: '',
    job_title: '',
    companies_id: -1,
    people_id: -1,
    ranking: '1'
    //If this was a relationship we could have InvestmendId which would display dropdown to choose from
};
const initial_company = {
    id: -1,
    name: '',
    description: '',
    specialty: '',
    region: '',
    company_type: '',
    company_state: '',
    company_city: '',
    company_street: '',
    other: '',
    size: '',
    ranking: -1
};

const useStyles = makeStyles(() => ({
    root: {}
}));

//Can use for create or edit by supplying intial_person as optional prop
export default function FormDialog({ type, info = initial_person, ...props }) {
    const classes = useStyles();
    const [values, setValues] = useState({});
    console.log(info);
    console.log(values);

    const [open, setOpen] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(initial_company);

    //For filling out possible companies
    function getCompanies() {
        complexGetSingle('companies?order=name.asc')
            .getAll()
            .then((res) => {
                console.log(res);
                setCompanies(res);
            });
    }

    function fetchCompany(id) {
        if (!id) return;

        // Joins people and companies, using the employs table automagicly
        // Use !inner to apply filter to subtable
        const { data, error } = supabase
            .from('companies')
            .select(
                `
                *,
                people!inner (id)
            `
            )
            .eq('people.id', id)
            .single()
            .then((res) => {
                if (res.error) console.log('error', res.error);
                else {
                    setSelectedCompany(res.data);
                }
            });
    }

    const handleClickOpen = () => {
        console.log('info', info);
        getCompanies();
        setValues(info);
        fetchCompany(info.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        console.log('submitted');
        props.handlePerson(values);
        handleClose();
    };

    //Handles majority of form elements
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    //Handles autocomplete tabs
    const handleAuto = (toAdd) => {
        //Combine the old list of info with the new information
        setValues({
            ...values,
            ...toAdd
        });
    };

    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
            >
                {type == 'create' ? 'Create' : 'Edit'}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <form autoComplete="off" noValidate>
                    <Card>
                        <CardHeader
                            subheader="Enter information about the contact"
                            title="Contact Information"
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Prefix"
                                        name="prefix"
                                        onChange={handleChange}
                                        required
                                        value={values.prefix}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Ranking"
                                        name="ranking"
                                        onChange={handleChange}
                                        required
                                        value={values.ranking}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="First name"
                                        name="first_name"
                                        onChange={handleChange}
                                        required
                                        value={values.first_name}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Last name"
                                        name="last_name"
                                        onChange={handleChange}
                                        required
                                        value={values.last_name}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Job Title"
                                        name="job_title"
                                        onChange={handleChange}
                                        value={values.job_title}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Email Address"
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Phone Number"
                                        name="phone_number"
                                        onChange={handleChange}
                                        value={values.phone_number}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Country"
                                        name="business_country"
                                        onChange={handleChange}
                                        value={values.business_country}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Select City"
                                        name="business_city"
                                        onChange={handleChange}
                                        value={values.business_city}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Enter Street"
                                        name="business_street"
                                        onChange={handleChange}
                                        value={values.business_street}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Autocomplete
                                        fullWidth
                                        // freeSolo
                                        id="company"
                                        label="Select Company"
                                        options={companies}
                                        getOptionLabel={(company) =>
                                            company.name +
                                            '   (' +
                                            company.region +
                                            ')'
                                        }
                                        onChange={(event, value) => {
                                            if (
                                                value != null &&
                                                value.id != null
                                            ) {
                                                console.log(value);
                                                handleAuto({
                                                    company: value.name,
                                                    companies_id: value.id
                                                });
                                                setSelectedCompany(value);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Company"
                                                variant="outlined"
                                            />
                                        )}
                                        value={selectedCompany}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                    />
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        label="Other info"
                                        name="other"
                                        onChange={handleChange}
                                        value={values.other}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </form>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Save Details
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
