import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
    palette: {
        background: {
            default: '#F4F6F8',
            paper: colors.common.white

            // Dark mode bg colour
            // default: '#1c1c1c',
            // paper: '#1c1c1c'
        },
        primary: {
            contrastText: '#ffffff',
            main: '#1038ad'
        },
        text: {
            primary: '#172b4d',
            secondary: '#6b778c'
        },
        secondary: {
            light: '#6ec6ff',
            main: '#2196f3',
            dark: '#0069c0',
            contrastText: '#fff'
        }
    },
    shadows,
    typography
});

export default theme;
