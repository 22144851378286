import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField,
    Autocomplete
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ranking } from './types';
import { validFieldValues } from '../../../utils/constants/types';

const useStyles = makeStyles(() => ({
    root: {}
}));

const ProfileDetails = ({
    className,
    handleChange,
    handleAuto,
    values,
    ...rest
}) => {
    console.log(values);
    const classes = useStyles();
    /*const [values, setValues] = useState({
    description: "",
    location: "",
    name: "",
    other: "",
    size: -1,
    specialty: ""
  });*/

    return (
        <form
            autoComplete="off"
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Card>
                <CardHeader
                    subheader="Enter details for company"
                    title="Company"
                />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                helperText="Please specify the company name"
                                label="Company name"
                                name="name"
                                onChange={handleChange}
                                required
                                value={values.name}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Size in $"
                                name="size"
                                onChange={handleChange}
                                type="ranking"
                                value={values.size}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Specialty"
                                name="specialty"
                                onChange={handleChange}
                                value={values.specialty}
                                variant="outlined"
                            />
                        </Grid>

                        {/**<Grid item xs={12} md={6}>
                            {/**! fix heere 
                            <Autocomplete
                                id="companyType"
                                options={companyType}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Company Type"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(event, value) => {
                                    if (value != null) {
                                        handleAuto({
                                            type: value
                                        });
                                    }
                                }}
                            />
                        </Grid>*/}
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Address"
                                name="company_street"
                                onChange={handleChange}
                                value={values.company_street}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="City"
                                name="company_city"
                                onChange={handleChange}
                                value={values.company_city}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="State"
                                name="company_state"
                                onChange={handleChange}
                                value={values.company_state}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                id="region"
                                value={values.region}
                                options={validFieldValues.region}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Company Region"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(event, value) => {
                                    if (value != null) {
                                        handleAuto({
                                            region: value
                                        });
                                    }
                                }}
                            />
                        </Grid>

                        {/**End changes */}
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                label="Other"
                                name="other"
                                onChange={handleChange}
                                value={values.other}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                label="Description"
                                name="description"
                                onChange={handleChange}
                                value={values.description}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Autocomplete
                                id="ranking"
                                value={ranking[values.ranking - 1]} // Values.ranking is not 0 indexed
                                options={ranking}
                                getOptionLabel={(option) => option.rankingText}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Ranking"
                                        variant="outlined"
                                    />
                                )}
                                onChange={(event, value) => {
                                    console.log(value);
                                    if (value != null) {
                                        handleAuto({
                                            ranking: value.ranking
                                        });
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    );
};

ProfileDetails.propTypes = {
    className: PropTypes.string
};

export default ProfileDetails;
