import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    Grid,
    Box,
    TextField,
    Card,
    Container,
    CardContent
} from '@material-ui/core';

import MeetingsShow from '../components/meetings/MeetingsShow';
import genericApi from '../apis/genericApi';
import nullHandler from '../utils/nullHandler';
import HandleMeeting from '../components/meetings/HandleMeeting';

const MeetingsList = ({ type = 'all', id }) => {
    const [meetings, setMeetings] = useState([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        getMeetings();
    }, []);

    function getMeetings() {
        if (type == 'specified') {
            const complexQuery = `combinedmeeting?people_id=cs.{${id}}`;
            genericApi('employs')
                .complexGetSingle(complexQuery, 'combinedmeeting')
                .then((res) => {
                    setMeetings(res);
                });
        } else {
            genericApi('combinedmeeting')
                .getAll()
                .then((res) => {
                    console.log(res);
                    setMeetings(res);
                });
        }
    }

    function handleChange(event) {
        setFilter(event.target.value);
    }

    const filteredData = meetings.filter((item) => {
        return Object.keys(item).some(
            (key) =>
                String(item[key]).toLowerCase().includes(filter.toLowerCase()) //.toLowerCase().includes(lowercasedFilter)
        );
    });

    function addMeeting(formInfo) {
        console.log(formInfo);
        //Main update object
        const meeting = formInfo;
        //Unique keys for tables (Excluding meeting_id)
        //Specify wanted keys:
        const meetsKeys = [
            'name',
            'date_of',
            'topic',
            'notes',
            'companies_id',
            'investments_id'
        ];
        const meetingPeopleKeys = ['people_id'];
        //Objects representing other dependent items that need to be update - i.e dependent relationships
        const meetingPeople = {};
        const meets = {};

        //Use bulk insert - https://postgrest.org/en/v7.0.0/api.html#bulk-insert

        //Upadte meetingPeopleKeys and remove unnessecary keys from meeting object
        meetingPeopleKeys.forEach(function (key, index) {
            //I.e set people_id: 1
            meetingPeople[key] = meeting[key];
            //delete meeting[key];
        });

        meetsKeys.forEach(function (key, index) {
            //I.e set people_id: 1
            meets[key] = meeting[key];
            //delete meeting[key];
        });

        //We now have meeting object ready to insert into meets
        //We also have meetingPeople object ready to insert into meetingPeople

        //Want to update relevant entity and relationship tables
        //So meets, and meets_people (As we have multiple possible people participating)
        console.log(meeting);
        //Update meets first to get id
        genericApi('meets')
            .post(meets)
            .then((res) => {
                console.log(res);
                const id = res[0].id;

                meeting.id = id;
                meetingPeople.meets_id = id;
                console.log(meetingPeople);

                //Update meetspeople after getting new meetsid
                if (meetingPeople.people_id !== null) {
                    //We need to update table for each person in array
                    const toPost = [];
                    meetingPeople.people_id.forEach(function (key, index) {
                        toPost.push({
                            meets_id: meetingPeople.meets_id,
                            people_id: meetingPeople.people_id[index]
                        });
                    });
                    console.log(toPost);
                    //Use bulk insert to minimise requests
                    genericApi('meetspeople')
                        .post(toPost)
                        .then((res) => {
                            console.log(res);
                        });
                }

                //! Ideally set null values to '' first to avoid errors on empty string. Alternatively can just refresh.
                //setMeetings(newMeetings);
                window.location.reload();
            });
    }

    function editMeeting(newMeeting) {
        console.log(newMeeting);
        const meeting = newMeeting;
        //Specify wanted keys:
        //! May need to change id to meeting_id
        const meetsKeys = [
            'id',
            'name',
            'date_of',
            'topic',
            'notes',
            'companies_id',
            'investments_id'
        ];
        const meetingPeopleKeys = ['people_id'];
        //Objects representing other dependent items that need to be update - i.e dependent relationships
        const meetingPeople = {};
        const meets = {};

        meetingPeopleKeys.forEach(function (key, index) {
            //I.e set people_id: 1
            meetingPeople[key] = meeting[key];
            //delete meeting[key];
        });
        meetsKeys.forEach(function (key, index) {
            //I.e set people_id: 1
            meets[key] = meeting[key];
            //delete meeting[key];
        });

        console.log(meets);
        console.log(nullHandler.addNulls(meets));
        console.log(meetingPeople);
        //! Potentially add nullhandler here
        genericApi('meets')
            .patch(meets, meets.id)
            .then((res) => {
                //meets['id'] = meets.id
                meetingPeople.meets_id = meets.id;
                const newMeetings = meetings.concat(meeting);
                //!We need multiple matches...
                //i.e http://localhost:3001/meetspeople?meets_id=eq.3&people_id=in.(8,4,46,69)
                //const complexQuery = `meetspeople?meets_id=eq.${meets.id}&people_id=in.(${meetingPeople['people_id']})`
                //Could also just have
                //http://localhost:3001/meetspeople?meets_id=eq.3
                const complexQuery = `meetspeople?meets_id=eq.${meets.id}`;
                console.log(meeting);
                console.log(meetingPeople);
                console.log(complexQuery);
                console.log('Check null');

                //Check that the meeting is valid, and that the people beind updated are relevant
                if (meetingPeople.meets_id != null) {
                    genericApi('meetspeople').complexRemove(complexQuery);
                    //Simplest solution (Probably not fastest)
                    //Delete all old people_id matching this meets
                    //Then update with new people
                    const toPost = [];
                    meetingPeople.people_id.forEach(function (key, index) {
                        console.log('Key is:');
                        console.log(key);
                        //Catch if there are no people to update
                        if (key != null && key != '') {
                            toPost.push({
                                meets_id: meetingPeople.meets_id,
                                people_id: meetingPeople.people_id[index]
                            });
                        }
                    });

                    console.log('About to patch');
                    console.log(nullHandler.addArrayOfObjectNulls(toPost));
                    console.log(toPost);
                    //! Just make this patch!
                    genericApi('meetspeople')
                        .post(toPost)
                        .then((res) => {
                            console.log(res);
                        });
                }

                //! Reload
                window.location.reload();
            });
    }

    return (
        <Container maxWidth={false}>
            {type === 'specified' ? (
                <h1 style={{ marginTop: '20px' }}>Meetings</h1>
            ) : (
                <Helmet>
                    <title>Meetings | CPD</title>
                </Helmet>
            )}

            <Box mt={2} display="flex">
                <Box flex="1">
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <TextField
                                size="small"
                                value={filter}
                                onChange={handleChange}
                                id="outlined-basic"
                                label="Search all meetings"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item>
                            <HandleMeeting
                                type="create"
                                handleMeeting={addMeeting}
                            />
                        </Grid>
                    </Grid>

                    {filteredData.length ? (
                        filteredData.map((meeting, index) => (
                            <Box sx={{ pt: 3 }} key={meeting.id}>
                                <Card>
                                    <CardContent>
                                        <MeetingsShow
                                            meeting={nullHandler.removeNulls(
                                                meeting
                                            )}
                                            key={meeting.id}
                                            editMeeting={editMeeting}
                                        />
                                    </CardContent>
                                </Card>
                            </Box>
                        ))
                    ) : (
                        <Box sx={{ pt: 3 }}>
                            <Card>
                                <CardContent>
                                    <p>No results</p>
                                </CardContent>
                            </Card>
                        </Box>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default MeetingsList;
