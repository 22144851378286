import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import PeopleListResults from '../components/customer/PeopleListResults';
import HandlePerson from '../components/customer/single/HandlePerson';
import genericApi from '../apis/genericApi';
import { supabase } from '../utils/supabaseClient';

const CustomerList = () => {
    const [customers, setCustomers] = useState([]);

    useEffect(() => {
        getPeople();
    }, []);

    function getPeople() {
        genericApi('people?order=id.desc')
            .getAll()
            .then((res) => {
                setCustomers(res);
            });
    }

    function addPerson(formInfo) {
        const people = formInfo;
        const employsKeys = ['companies_id', 'people_id'];
        const employs = {};

        employsKeys.forEach(function (key, index) {
            console.log(key);
            employs[key] = people[key];
            delete people[key];
        });

        console.log(people);
        console.log(employs);

        //We want to update the people table with the relevant information from the form.
        //We then want to update the employs table with where that person works

        // Post update, returning the id of the new person
        const { data, error } = supabase
            .from('people')
            .insert([people])
            .select('id')
            .single()
            .then((res) => {
                console.log(res.data.id);

                people.id = res.data.id;
                employs.people_id = res.data.id;
                const newPeople = customers.concat(people);
                if (employs.companies_id != -1) {
                    supabase
                        .from('employs')
                        .insert([employs])
                        .then((res) => {
                            console.log(res);
                        });
                }
                //Update list
                setCustomers(newPeople);
            });

        //window.location.reload();
    }

    return (
        <>
            <Helmet>
                <title>People | CPD</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <HandlePerson type="create" handlePerson={addPerson} />
                    <Box sx={{ pt: 3 }}>
                        <PeopleListResults customers={customers} />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default CustomerList;
