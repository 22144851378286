//! This is just person atm - need to change to be investment info (DO after person is finalised)
import { useState, useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import XLSX from 'xlsx';
import genericApi from '../apis/genericApi';
import InvestmentShow from '../components/investment/InvestmentShow';
import nullHandler from '../utils/nullHandler';
import CurrentInvestments from '../components/companies/Related/CurrentInvestments/CurrentInvestments';

const Investment = () => {
    const [investment, setInvestment] = useState([]);
    const [currentInvestments, setCurrentInvestments] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getInvestment();
    }, []);

    function getInvestment() {
        genericApi('investments')
            .getSingle(id)
            .then((res) => {
                setInvestment(nullHandler.removeNulls(res[0]));
            });

        genericApi()
            .complexGetSingle(`currentinvestments?investments_id=eq.${id}`)
            .then((res) => {
                setCurrentInvestments(res);
            });
    }

    function editInvestment(newInvestment) {
        genericApi('investments')
            .patch(nullHandler.addNulls(newInvestment), newInvestment.id)
            .then((res) => {
                window.location.reload();
                console.log(res);
            });
    }

    function generateExcel() {
        currentInvestments.forEach(function (item) {
            delete item.companies_id;
            delete item.investments_id;
        });
        /* original data */
        var investmentData = [investment];
        console.log(currentInvestments);
        /* make the worksheet */
        var worksheet = XLSX.utils.json_to_sheet(investmentData);
        XLSX.utils.sheet_add_json(worksheet, currentInvestments, {
            origin: 'A4'
        });
        // var worksheet = XLSX.utils.aoa_to_sheet(data); // XLSX.utils.json_to_sheet(data);
        // sheet_add_aoa

        /* add to workbook */
        var workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Investment');

        /* generate an XLSX file */
        XLSX.writeFile(workbook, 'Investment.xlsx');
    }

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 3
            }}
        >
            <Container maxWidth="lg">
                <InvestmentShow
                    investment={investment}
                    handleInvestment={editInvestment}
                    generateExcel={generateExcel}
                />
                <CurrentInvestments
                    id={id}
                    type={'investment'}
                    style={{ height: 600 }}
                />
            </Container>
        </Box>
    );
};

export default Investment;
