import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography
} from '@material-ui/core';
import genericApi from '../../../apis/genericApi';
import HandlePerson from './HandlePerson';
//For api

const PersonShow = (props) => {
    const person = props.person;
    const handlePerson = props.handlePerson;

    const deletePerson = (id) => {
        genericApi('people')
            .remove(id)
            .then((res) => {
                console.log(res);
                // Redirect after delete
                window.location.href = '/cpd/people';
            });
    };

    return (
        <Card>
            <CardHeader
                title={person.first_name + ' ' + person.last_name}
                action={
                    <>
                        <HandlePerson
                            type="edit"
                            handlePerson={handlePerson}
                            info={person}
                        />
                        {/* Gap */}
                        <span style={{ margin: '0 10px' }}></span>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (
                                    window.confirm(
                                        'Are you sure you want to delete this person?'
                                    )
                                ) {
                                    deletePerson(person.id);
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </>
                }
                titleTypographyProps={{ variant: 'h2' }}
                subheader={person.job_title + ' at ' + person.company}
            />
            <Divider />
            <CardContent>
                <Typography component={'div'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <p>{person.phone_number}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p>{person.email}</p>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                <p>
                                    {person.business_street +
                                        ' ' +
                                        person.business_city +
                                        ' ' +
                                        person.business_state +
                                        ' ' +
                                        person.business_country}
                                </p>
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <b>Other info:</b>
                            {<p>{person.other}</p>}
                        </Grid>
                    </Grid>
                </Typography>
            </CardContent>
            <Divider />
        </Card>
    );
};

export default PersonShow;
