// These are sent to the database as a ranking between 1 and 10
export const ranking = [
    {
        ranking: 1,
        rankingText: 'Top Priority Investor'
    },
    {
        ranking: 2,
        rankingText: 'Priority Investor'
    },
    {
        ranking: 3,
        rankingText: 'New Investor- need to research more'
    },
    {
        ranking: 4,
        rankingText: 'Secondary Investor'
    },
    {
        ranking: 5,
        rankingText: 'Unlikely Investors'
    },
    {
        ranking: 6,
        rankingText: 'Not relevant for this product'
    },
    {
        ranking: 7,
        rankingText: 'Advised No Interest in Australia'
    },
    {
        ranking: 8,
        rankingText: 'Need to amend data in database'
    },
    {
        ranking: 9,
        rankingText: 'Keep in System but for information purposes only'
    },
    {
        ranking: 10,
        rankingText: 'Left - Need to Delete from System'
    }
];
