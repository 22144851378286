import React, { useState, useEffect } from 'react';

//Material UI
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import CompaniesDataGrid from '../components/companies/Datagrid';
import genericApi from '../apis/genericApi';
import { Helmet } from 'react-helmet';
import { Box } from '@material-ui/core';
//CSS
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    fixedHeight: {
        height: 240
    },
    innerHeader: {
        margin: 'auto'
    }
}));

export default function Companies() {
    //Styling for material ui

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        getCompanies();
    }, []);

    function getCompanies() {
        genericApi('companies?order=id.desc')
            .getAll()
            .then((res) => {
                setCompanies(res);
            });
    }

    const classes = useStyles();
    return (
        <>
            <Helmet>
                <title>Companies | CPD</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%'
                }}
            >
                <Container maxWidth={false} className={classes.container}>
                    <CompaniesDataGrid companies={companies} />
                </Container>
            </Box>
        </>
    );
}
