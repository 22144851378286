// Use datagrid to show people

import * as React from 'react';
import { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { supabase } from '../../utils/supabaseClient';
import { Card, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'More',
        width: 75,
        renderCell: (params) => (
            <IconButton component={Link} to={`/cpd/people/${params.id}/show`}>
                <InfoIcon color="primary" />
            </IconButton>
        ),
        filterable: false,
        disableColumnMenu: true,
        sortable: false
    },
    { field: 'prefix', headerName: 'Prefix', width: 125 },
    { field: 'first_name', headerName: 'First Name', width: 160 },
    { field: 'last_name', headerName: 'Last Name', width: 160 },
    { field: 'phone_number', headerName: 'Phone', width: 150 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'company', headerName: 'Company', width: 250 },
    { field: 'job_title', headerName: 'Job Title', width: 250 },
    { field: 'business_city', headerName: 'Business City', width: 250 },
    { field: 'business_state', headerName: 'Business State', width: 250 },
    { field: 'business_country', headerName: 'Business Country', width: 250 },
    { field: 'ranking', headerName: 'Ranking', width: 150 }
];

export default function CompanyPeople({ company_id }: { company_id: number }) {
    console.log(company_id);
    const [rows, setRows] = useState<any>([]);

    useEffect(() => {
        fetchPeople();
        console.log(company_id);
    }, [company_id]);

    async function fetchPeople() {
        if (!company_id) return;

        // Joins people and companies, using the employs table automagicly
        // Use !inner to apply filter to subtable
        const { data, error } = await supabase
            .from('people')
            .select(
                `
                *,
                companies!inner (id)
            `
            )
            .eq('companies.id', company_id);
        if (error) console.log('error', error);
        else setRows(data);
    }

    return (
        // Should size itself to fit the child component
        <Card style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                checkboxSelection
            />
        </Card>
    );
}
