// Database types
export const validFieldValues = {
    deal_type: [
        'Funds',
        'Debt',
        'Direct Property',
        'Joint Venture',
        'Club',
        'Separate Account',
        'Other Type'
    ],

    company_type: [
        'Broker',
        'Fund Manager',
        'Investor',
        'Bank',
        'Non-bank',
        'High-Net-Worth',
        'Other',
        'Advisor'
    ],

    target_returns: [
        'Core',
        'Core Plus',
        'Value Add',
        'Opportunistic',
        'Other Return'
    ],

    is_looking: ['Actively', 'General', 'Not', 'Previously'],

    region: [
        'Australia',
        'Asia Pac (ex Aust)',
        'Middle East',
        'Europe/UK',
        'North America',
        'Rest of World'
    ],

    sector: [
        'Office',
        'Retail',
        'Industrial',
        'BTR',
        'Student Accommodation',
        'Medical/Health',
        'Residential Development',
        'Diversified',
        'Other',
        'Hotels'
    ],

    investment_location: [
        'Australia',
        'New Zealand',
        'Pan Asia',
        'Singapore',
        'China/HK',
        'Japan',
        'UK',
        'Europe',
        'USA',
        'Canada',
        'North America',
        'Other'
    ],

    ranking: [
        [1, 'Top Priority Investor'],
        [2, 'Priority Investor'],
        [3, 'New Investor- need to research more'],
        [4, 'Secondary Investor'],
        [5, 'Unlikely Investors'],
        [6, 'Not relevant for this product'],
        [7, 'Advised No Interest in Australia'],
        [8, 'Need to amend data in database'],
        [9, 'Keep in System but for information purposes only'],
        [10, 'Left - Need to Delete from System']
    ]
};
