import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import InvestmentList from '../components/investment/InvestmentList';
import HandleInvestment from '../components/investment/HandleInvestment';
import genericApi from '../apis/genericApi';
const Investments = () => {
    const [investments, setInvestments] = useState([]);

    useEffect(() => {
        getInvestments();
    }, []);

    function getInvestments() {
        genericApi('investments')
            .getAll()
            .then((res) => {
                setInvestments(res);
            });
    }

    function addInvestment(investment) {
        console.log(investment);
        genericApi('investments')
            .post(investment)
            .then((res) => {
                investment.id = res[0].id;
                const newInvestments = investments.concat(investment);
                setInvestments(newInvestments);
                //window.location.reload()
            });
    }

    return (
        <>
            <Helmet>
                <title>Investments | CPD</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                <Container maxWidth={false}>
                    <HandleInvestment
                        type="create"
                        action={addInvestment}
                        handleInvestment={addInvestment}
                    />
                    <Box sx={{ pt: 3 }}>
                        <InvestmentList investments={investments} />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default Investments;
