import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import genericApi from '../../apis/genericApi';
import complexGetSingle from '../../apis/genericApi';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Autocomplete
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import auLocale from 'date-fns/locale/en-AU';

//Have json representation for each table we will insert via api
const initial_meeting = {
    //id: 0,
    name: '',
    date_of: new Date().toDateString(),
    topic: '',
    notes: '',
    companies_id: null,
    people_id: null,
    investments_id: null
};

const useStyles = makeStyles(() => ({
    root: {}
}));

function Asynchronous({ table, initialValue, handleInput }) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    const initialPeople = [];

    //! Alternate
    if (initialValue.people_id != null) {
        console.log(initialValue);
        initialValue.people_id.forEach(function (key, index) {
            initialPeople.push({
                id: initialValue.people_id[index],
                first_name: initialValue.person_name[index], //Not sure we can rely on ordered indexes
                last_name: '' //just to trick as we get first and lastname combiend in given values
            });
        });
    }

    //For setting default people value when editing
    function getStartingOptions() {
        var newOptions = [];
        //console.log(info)
        //console.log(people)
        console.log(initialValue);
        initialValue.forEach((id) => {
            //For every person id we are given, add the matching object to the new array
            //Find the index of the id, then put that object into array
            //console.log(person_id)
            //console.log(people.map(e => e.id).indexOf(person_id))
            newOptions.push(options[options.map((e) => e.id).indexOf(id)]);
        });
        console.log(newOptions);
        console.log(options);
        return newOptions;
    }

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const response = await genericApi(table).getAll();
            //await sleep(1); // For demo purposes.
            const result = await response;
            if (active) {
                setOptions(result);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    //Think this could be causing issues on submit
    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            multiple
            id="people"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
                option.first_name + ' ' + option.last_name
            }
            options={options}
            defaultValue={initialPeople}
            loading={loading}
            onChange={(event, value) => {
                if (value != null) {
                    //! Map array of objects to an array of objects with info we want
                    handleInput({
                        person_name: value.map(
                            (a) => a.first_name + ' ' + a.last_name
                        ),
                        people_id: value.map((a) => a.id)
                    });
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select People"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    );
}

export default function FormDialog({ type, info = initial_meeting, ...props }) {
    const classes = useStyles();
    const [values, setValues] = useState({});

    const [open, setOpen] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [investments, setInvestments] = useState([]);
    //Actually works!! Test more thoroughly then probably replace all api calls?
    const [people, setPeople] = useState([]);

    const initialValue = info;
    const initialCompany = {
        id: info.companies_id,
        name: info.company_name
    };

    const initialInvestment = {
        id: info.investments_id,
        name: info.investment_name
    };

    /*{
    "id": 47,
    "name": "Lemke - Auer",
    "description": "incremental",
    "specialty": "real-time",
    "region": "North America",
    "company_state": "Connecticut",
    "company_city": "Adolphusfurt",
    "company_street": "36758 Yundt Squares",
    "other": "I'll bypass the bluetooth XSS alarm, that should microchip the RAM matrix!",
    "size": 73004
  }*/

    /*console.log(info);
    console.log(values);
    console.log(companies);
    console.log(investments);
    console.log(
        companies[companies.map((e) => e.id).indexOf(info.companies_id)] || ''
    );*/

    //For filling out possible companies
    function getCompanies() {
        complexGetSingle('companies?order=name.asc')
            .getAll()
            .then((res) => {
                setCompanies(res);
            });
    }

    function getInvestments() {
        complexGetSingle('investments?order=name.asc')
            .getAll()
            .then((res) => {
                setInvestments(res);
            });
    }

    const handleClickOpen = () => {
        getCompanies();
        getInvestments();
        setValues(info);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        console.log('submitted');
        console.log(values);
        props.handleMeeting(values);
        handleClose();
    };

    //Handles majority of form elements
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    //Handles autocomplete tabs
    const handleAuto = (toAdd) => {
        //Combine the old list of info with the new information
        console.log(values);
        console.log(toAdd);
        setValues({
            ...values,
            ...toAdd
        });
    };

    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
            >
                {type == 'create' ? 'Create' : 'Edit'}
            </Button>

            {/** Change width of dialog using maxWidth */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                aria-labelledby="form-dialog-title"
            >
                <form autoComplete="off" noValidate>
                    <Card>
                        <CardHeader
                            subheader="Enter information about the meeting"
                            title="Meeting Information"
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                {/* Name no longer wanted */}
                                {/* <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        helperText="Name of meeting"
                                        label="Name"
                                        name="name"
                                        onChange={handleChange}
                                        required
                                        value={values.name}
                                        variant="outlined"
                                    />
                                </Grid> */}
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Meeting Topic"
                                        name="topic"
                                        onChange={handleChange}
                                        required
                                        value={values.topic}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Autocomplete
                                        fullWidth
                                        id="company"
                                        label="Select Relevant Company"
                                        options={companies}
                                        getOptionLabel={(company) =>
                                            company.name +
                                            '   (' +
                                            company.region +
                                            ')'
                                        }
                                        defaultValue={initialCompany}
                                        //defaultValue={companies[companies.map(e => e.id).indexOf(info.companies_id)] || companies[0]}
                                        onChange={(event, value) => {
                                            if (value != null) {
                                                console.log(value);
                                                handleAuto({
                                                    company_name: value.name,
                                                    companies_id: value.id
                                                });
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Company"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Autocomplete
                                        id="investment"
                                        label="Select Relevant Investment"
                                        options={investments}
                                        getOptionLabel={(investment) =>
                                            investment.name +
                                            '   (' +
                                            investment.sector +
                                            ')'
                                        }
                                        defaultValue={initialInvestment}
                                        onChange={(event, value) => {
                                            if (value != null) {
                                                console.log(value);
                                                handleAuto({
                                                    investment_name: value.name,
                                                    investments_id: value.id
                                                });
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Select Relevant Investment"
                                                variant="outlined"
                                            >
                                                {console.log(params)}
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    {/** 
                  <Autocomplete
                    multiple
                    id="person"
                    label="Select Participants"
                    options={people}
                    getOptionLabel={(person) => person.first_name}
                    defaultValue={getStartingPeople()}
                    onChange={(event,value) => {
                      if(value != null){
                        console.log(value)
                        handleAuto({
                          person: value.first_name+ ' ' + value.last_name,
                          people_id: value.id
                        })
                      }
                    }}
                    renderInput={(params) => 
                    <TextField {...params} label="Select Participants" variant="outlined" />
                    }
                  />*/}
                                    <Asynchronous
                                        table="people"
                                        initialValue={info}
                                        handleInput={handleAuto}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={auLocale}
                                    >
                                        <DatePicker
                                            label="Date"
                                            name="Date"
                                            value={values.date_of}
                                            onChange={(newValue) => {
                                                setValues({
                                                    ...values,
                                                    date_of:
                                                        newValue.toDateString()
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        label="Notes"
                                        name="notes"
                                        onChange={handleChange}
                                        value={values.notes}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </form>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Save Details
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
