// Very simple sign up page using Supabase auth and material UI

import React, { useState } from 'react';
import { supabase } from '../utils/supabaseClient';
import { Link } from 'react-router-dom';
import {
    TextField,
    Typography,
    Box,
    Button,
    Container
} from '@material-ui/core';

export default function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSignUp = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const { error } = await supabase.auth.signUp({ email, password });
        if (error) {
            alert(error.message);
        } else {
            alert('Check your email for the login link!');
        }
        setLoading(false);
    };

    return (
        <Container maxWidth="sm">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Sign Up
                </Typography>
                <form noValidate autoComplete="off" onSubmit={handleSignUp}>
                    <TextField
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Loading ...' : 'Sign Up'}
                    </Button>
                </form>
                <Typography variant="body1" gutterBottom>
                    Already have an account? <Link to="/login">Log In</Link>
                </Typography>
            </Box>
        </Container>
    );
}
