import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Person from './pages/Person';
import CustomerList from './pages/PeopleList';
import MeetingsList from './pages/MeetingsList';
import Companies from './pages/Companies';
import Company from './pages/Company';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Investments from './pages/Investments';
import Investment from './pages/Investment';
import Seeking from './pages/SeekingPage';
import Glossary from './pages/Glossary';
import SignUp from './pages/SignUp';
import PasswordReset from './pages/ResetPassword';

const routes = (isLoggedIn) => {
    return [
        {
            path: '/cpd/',
            element: isLoggedIn ? (
                <DashboardLayout />
            ) : (
                <Navigate to="/login" />
            ),
            children: [
                { path: 'people', element: <CustomerList /> },
                { path: 'people/:id/show', element: <Person /> },
                { path: 'meetings', element: <MeetingsList /> },
                { path: 'companies', element: <Companies /> },
                { path: 'companies/:id', element: <Company /> },
                { path: 'seeking', element: <Seeking /> },
                { path: 'investments', element: <Investments /> },
                { path: 'investments/:id/show', element: <Investment /> },
                { path: 'glossary', element: <Glossary /> },
                { path: 'reset-password', element: <PasswordReset /> },
                { path: '*', element: <Navigate to="/404" /> }
            ]
        },
        {
            path: '/',
            element: <MainLayout />,
            children: [
                { path: '404', element: <NotFound /> },
                { path: '', element: <Navigate to="/cpd/people" /> },
                { path: '*', element: <Navigate to="/404" /> },
                {
                    path: 'login',
                    element: isLoggedIn ? (
                        <Navigate to="/cpd/people" />
                    ) : (
                        <Login />
                    )
                },
                { path: 'signup', element: <SignUp /> }
            ]
        }
    ];
};

export default routes;
