import { Box, Card, Container, CardContent } from '@material-ui/core';
import { validFieldValues } from '../utils/constants/types';
import * as React from 'react';

const Glossary = () => {
    // List all types from validFieldValues in invidivual cards using material ui.
    // These cards should use flexbox to be responsive.
    const printAllTypes = () =>
        Object.keys(validFieldValues).map((key) => (
            // Card that determines width based on content
            <Card
                sx={{
                    minWidth: 250,
                    maxWidth: 500,
                    margin: 2,
                    display: 'inline-flex'
                }}
                key={key}
            >
                <CardContent>
                    <h2>{key}</h2>
                    {validFieldValues[key].map((value) => (
                        <li key={key}> {value} </li>
                    ))}
                </CardContent>
            </Card>
        ));

    return (
        <Container maxWidth={false}>
            <Box mt={2} display="flex">
                <Box flex="1">
                    <Box sx={{ pt: 3 }}>
                        <Card>
                            <CardContent>
                                <h1>Brief summary of terminology</h1>
                                <br />
                                {printAllTypes()}
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export default Glossary;
