import { Card } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
//For api
import { Link } from 'react-router-dom';

//For search in datagrid
import { DataGrid } from '@material-ui/data-grid';

import * as React from 'react';
import QuickSearchToolbar, { escapeRegExp } from '../utils/QuickSearchToolbar';

const PeopleListResults = ({ customers }) => {
    const columns = [
        {
            field: 'id',
            headerName: 'More',
            width: 75,
            // eslint-disable-next-line react/display-name
            renderCell: (params) => (
                <IconButton
                    component={Link}
                    to={`/cpd/people/${params.id}/show`}
                >
                    <InfoIcon color="primary" />
                </IconButton>
            ),
            disableColumnFilter: true,
            disableColumnMenu: true,
            sortable: false
        },
        { field: 'prefix', headerName: 'Prefix', width: 125 },
        { field: 'first_name', headerName: 'First Name', width: 160 },
        { field: 'last_name', headerName: 'Last Name', width: 160 },
        { field: 'phone_number', headerName: 'Phone', width: 150 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'company', headerName: 'Company', width: 250 },
        { field: 'job_title', headerName: 'Job Title', width: 250 },
        { field: 'business_city', headerName: 'Business City', width: 250 },
        { field: 'business_state', headerName: 'Business State', width: 250 },
        {
            field: 'business_country',
            headerName: 'Business Country',
            width: 250
        },
        {
            field: 'ranking',
            headerName: 'Ranking',
            width: 150
        }
    ];

    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = React.useState(customers);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = customers.filter((row) => {
            return Object.keys(row).some((field) => {
                if (row[field] == null) {
                    return false;
                } else {
                    return searchRegex.test(row[field].toString());
                }
            });
        });
        setRows(filteredRows);
    };

    React.useEffect(() => {
        setRows(customers);
    }, [customers]);

    return (
        <Card style={{ height: 800, width: '100%' }}>
            <DataGrid
                columns={columns}
                rows={rows}
                pageSize={10}
                // components={{
                //     Toolbar: GridToolbar
                // }}
                components={{ Toolbar: QuickSearchToolbar }}
                filterModel={{
                    items: [
                        {
                            columnField: 'first_name',
                            operatorValue: 'contains',
                            value: ''
                        }
                    ]
                }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch('')
                    }
                }}
                checkboxSelection
            />
        </Card>
    );
};

export default PeopleListResults;
