//This began as a solution to person_name.map is not a function (Was returning "")
//Then solved that problem by making that turn into [""]
//This then caused items that should have been "" to turn into [""]
//In summary, would have been more simply solved by ensuring form returned correct type in the first place.
//Would have been helped by a proper backend.

/**
 * Provide with an object, returns the same object with empty strings convertedto null
 * @param {*} o
 * @returns
 */
// eslint-disable-next-line no-unused-vars
function addNulls(o) {
    //Function to remove null values from api object.
    //Needed for input form to not throw errors.

    for (var key in o) {
        if (o[key] === null) {
            o[key] = null;
        } else if (o[key] === '') {
            o[key] = null;
        } else if (o[key].constructor === Array) {
            o[key] = addNullsToArray(o[key]);
        }
    }
    return o;
}

//If need to add nulls in an array:
function addNullsToArray(givenArray) {
    //Function to remove null values from api object.
    //Needed for input form to not throw errors.
    if (givenArray[0] == '') return null;

    return givenArray;
}

//If need to add nulls in an array:
function addArrayOfObjectNulls(givenArray) {
    //Function to remove null values from api object.
    //Needed for input form to not throw errors.
    givenArray.forEach(function (item, index, givenArray) {
        for (var key in givenArray[index]) {
            if ('' === givenArray[index][key]) givenArray[index][key] = null;
        }
    });
    return givenArray;
}

/**
 * Provide with an object, returns the same object without nulls
 * @param {*} o
 * @returns
 */
// eslint-disable-next-line no-unused-vars
/*function removeNulls(o) {
    //Function to remove null values from api object.
    //Needed for input form to not throw errors.

    for (var key in o) {
        if (o[key] === null) o[key] = '';
    }
    return o;
}*/

function removeNulls(o) {
    //Function to remove null values from api object.
    //Needed for input form to not throw errors.
    for (var key in o) {
        if (null === o[key]) {
            //set to array so things like people.person_name.map doesnt fail, it just iterates through [''] instead
            o[key] = [''];
            //This isn't a great solution as some things need to be arrays and some strings.
        }
    }
    return o;
}

//If need to removenullsin an array:
function removeArrayNulls(givenArray) {
    //Function to remove null values from api object.
    //Needed for input form to not throw errors.
    givenArray.forEach(function (item, index, givenArray) {
        for (var key in givenArray[index]) {
            if (null === givenArray[index][key]) givenArray[index][key] = '';
        }
    });
    console.log(givenArray);
    return givenArray;
}

export default {
    addNulls,
    addNullsToArray,
    addArrayOfObjectNulls,
    removeNulls,
    removeArrayNulls
};
