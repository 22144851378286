import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography
} from '@material-ui/core';

import { supabase } from '../utils/supabaseClient';

const Login = () => {
    const navigate = useNavigate();
    supabase.auth.getSession().then(({ data: { session } }) => {
        console.log(session);
    });

    const handleLogin = async (email, password) => {
        try {
            const { error } = await supabase.auth.signInWithPassword({
                email,
                password
            });
            if (error) throw error;
            navigate('/cpd/people', { replace: true });
        } catch (error) {
            alert(error.error_description || error.message);
        }
    };

    return (
        <>
            <Helmet>
                <title>Login | CPD</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'center'
                }}
            >
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email('Must be a valid email')
                                .max(255)
                                .required('Email is required'),
                            password: Yup.string()
                                .max(255)
                                .required('Password is required')
                        })}
                        onSubmit={(content) => {
                            console.log('Submitted');
                            // AuthService.login(
                            // content.email,
                            // content.password
                            // ).
                            handleLogin(content.email, content.password);
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values
                        }) => (
                            <form>
                                <Box sx={{ mb: 3 }}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Sign in
                                    </Typography>
                                </Box>

                                <TextField
                                    error={Boolean(
                                        touched.email && errors.email
                                    )}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                    autoComplete="email"
                                />
                                <TextField
                                    error={Boolean(
                                        touched.password && errors.password
                                    )}
                                    fullWidth
                                    helperText={
                                        touched.password && errors.password
                                    }
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password}
                                    variant="outlined"
                                    autoComplete="password"
                                />
                                <Box sx={{ py: 2 }}>
                                    <Button
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={handleSubmit}
                                    >
                                        Sign in now
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </>
    );
};

export default Login;
