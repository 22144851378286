import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
//Material UI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
//Additional dependencies
import clsx from 'clsx';

//Companies
import Details from '../components/companies/Company/companyInfo';
import CurrentInvestments from '../components/companies/Related/CurrentInvestments/CurrentInvestments';
import SeekingInvestmentsDatagrid from '../components/companies/Related/SeekingInvestments/SeekingInvestments';
import genericApi from '../apis/genericApi';
import CompanyPeople from '../components/customer/CompanyPeople';

//CSS
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    },
    fixedHeight: {
        height: 240
    },
    fixedPaperHeight: {
        height: 360
    },
    highFixedPaperHeight: {
        height: 800
    },
    innerHeader: {
        margin: 'auto'
    }
}));

//Initialisation values
const initialValue = {
    description: '',
    name: '',
    other: '',
    size: null,
    specialty: '',
    region: '',
    company_state: '',
    company_city: '',
    company_street: ''
};

export default function Company() {
    const classes = useStyles(); //Styling for material ui
    const { id } = useParams(); // Get param from url
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const fixedPaperHeight = classes.fixedPaperHeight;

    //This uses react hooks to keep state - this is why we can use a funciton isntead of class
    //Usestate initialises persons - i.e. could be [{ name: 'Learn Hooks' }] OR props!
    //const [persons, setPersons] = useState(props);
    const [companies, setCompanies] = useState([initialValue]);
    const [selectedCompany, setCompany] = useState(initialValue);

    // Part of initialisation
    // If id != null, we want to set the autocomplete to our company
    function initialiseCompany() {
        if (id != null) {
            const specifiedCompany = companies.filter(
                (company) => company.id == id
            );
            // Only set this if ID is present in companies list
            if (specifiedCompany.length == 1) {
                setCompany(specifiedCompany[0]);
            }
        }
    }

    const selectCompany = (event, value) => {
        const name = value;
        if (value === null) {
            setCompany({
                id: null,
                name: null
            });
        } else {
            console.log(value);
            setCompany({
                //... Destructure result, not actually needed if we removed {}
                ...value
            });
        }
    };

    const editCompany = (event) => {
        /*
        Too hard!! trying to update all companies when index is updated
        console.log('name' + event.target.name);
        console.log('value '+ event.target.value);
        let newCompanies = [...companies]; // copying the old datas array
        //This assumes companies are ordered by id...
        newCompanies[selectedCompany.id] = {
            ...selectedCompany,
            [event.target.name]: event.target.value
        };

        setCompany({
            ...selectedCompany,
            [event.target.name]: event.target.value
          });
        //This requires double copying list (i think) but is probably fine as long as it's small
        setCompanies(newCompanies)
        */
    };

    useEffect(
        () => {
            // Update the document title using the browser API
            //https://api.countapi.xyz/hit/testlocalhotessss/key
            //https://jsonplaceholder.typicode.com/users
            /*axios.get(process.env.REACT_APP_API_ENDPOINT+`/companies`).then(res => {
                const companies = res.data;
                console.log(res);
                setCompanies(companies);
            });*/

            genericApi('companies?order=name')
                .getAll()
                .then((res) => {
                    const companies = res;
                    console.log(res);
                    setCompanies(companies);
                });
        },
        //Useeffect recalls if current value != prev value
        //But in JS comparing two objects by === is always false!
        //So we use another point of comparison
        //Used to be [companies.length] but this caused it to fire twice!
        []
    );

    // If id is provided in url, update display
    useEffect(() => {
        initialiseCompany();
    }, companies);

    return (
        <Container maxWidth={false} className={classes.container}>
            <Helmet>
                <title>Companies | CPD</title>
            </Helmet>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                    <Paper className={fixedHeightPaper} sx={{ mb: 3, pb: 1 }}>
                        <Details
                            company={selectedCompany}
                            setCompany={setCompany}
                        />
                    </Paper>
                    <SeekingInvestmentsDatagrid
                        type="specified"
                        id={selectedCompany.id}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CurrentInvestments
                        id={selectedCompany.id}
                        type={'company'}
                        className={classes.highFixedPaperHeight}
                    />
                </Grid>
            </Grid>
            <div style={{ marginTop: '20px' }}>
                <CompanyPeople company_id={selectedCompany.id} />
            </div>
            {/* <Paper className={fixedHeightPaper}>
                <Details company={selectedCompany}></Details>
            </Paper>
            <CurrentInvestments
                        id={selectedCompany.id}
                        type={"company"}
                        className={fixedPaperHeight}
                        sx={{ mt: 3, mb:3, pb:1 }}
                    />
            <SeekingInvestmentsDatagrid type="specified" id={selectedCompany.id} /> */}
        </Container>
    );
}
