import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Autocomplete
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import nullHandler from '../../utils/nullHandler';
import { validFieldValues } from '../../utils/constants/types';

//Have json representation for each table we will insert via api
const initial_investment = {
    name: '',
    target_returns: null,
    description: '',
    reports: '',
    deal_type: null,
    sector: null,
    location: '',
    rate: 0
};

const useStyles = makeStyles(() => ({
    root: {}
}));

export default function FormDialog({
    type,
    info = initial_investment,
    ...props
}) {
    const classes = useStyles();
    const [values, setValues] = useState({});

    const [open, setOpen] = useState(false);
    const [investments, setInvestments] = useState([]);

    const handleClickOpen = () => {
        setValues(info);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        console.log('submitted');
        console.log(nullHandler.addNulls(values));
        props.handleInvestment(values);
        handleClose();
    };

    //Handles majority of form elements
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    //Handles autocomplete tabs
    const handleAuto = (toAdd) => {
        //Combine the old list of info with the new information
        console.log(values);
        console.log(toAdd);
        setValues({
            ...values,
            ...toAdd
        });
    };

    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
            >
                {type == 'create' ? 'Create' : 'Edit'}
            </Button>

            {/** Change width of dialog using maxWidth */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                aria-labelledby="form-dialog-title"
            >
                <form autoComplete="off" noValidate>
                    <Card>
                        <CardHeader
                            subheader="Enter information about the investment"
                            title="Investment Information"
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        helperText="Name of investment"
                                        label="Name"
                                        name="name"
                                        onChange={handleChange}
                                        required
                                        value={values.name}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        helperText="Location of investment"
                                        label="Location"
                                        name="location"
                                        onChange={handleChange}
                                        required
                                        value={values.location}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Investment rate (%)"
                                        name="rate"
                                        type="number"
                                        onChange={handleChange}
                                        value={values.rate}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Autocomplete
                                        id="target_returns"
                                        options={
                                            validFieldValues.target_returns
                                        }
                                        value={values.target_returns}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Target Returns"
                                                variant="outlined"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            if (value != null) {
                                                handleAuto({
                                                    target_returns: value
                                                });
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Autocomplete
                                        id="deal_type"
                                        options={validFieldValues.deal_type}
                                        value={values.deal_type}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Deal Type"
                                                variant="outlined"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            if (value != null) {
                                                handleAuto({
                                                    deal_type: value
                                                });
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    {/**SHOULD USE inputValue here too but cbf - refer to controllable states in docs */}
                                    <Autocomplete
                                        id="sector"
                                        options={validFieldValues.sector}
                                        value={values.sector}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Sector"
                                                variant="outlined"
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            if (value != null) {
                                                handleAuto({
                                                    sector: value
                                                });
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        label="Description"
                                        name="description"
                                        onChange={handleChange}
                                        value={values.description}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </form>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Save Details
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
