import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Autocomplete
} from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import auLocale from 'date-fns/locale/en-AU';
import genericApi from '../../../../apis/genericApi';

//Global vars
const initialSeeking = {
    companies_id: null,
    company: '',
    investments_id: null,
    investment: '',
    date_entered: new Date().toDateString(),
    amount: 0,
    description: ''
};

//Can use for create or edit by changing the type and supplying prop
export default function FormDialog({ type, info = initialSeeking, ...props }) {
    const [values, setValues] = useState({});
    const [open, setOpen] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [investments, setInvestments] = useState([]);

    const initialCompany = {
        id: info.companies_id
    };

    const initialInvestment = {
        id: info.investments_id,
        name: info.name
    };

    //For filling out possible companies
    function getCompanies() {
        genericApi('companies')
            .getAll()
            .then((res) => {
                setCompanies(res);
            });
    }

    function getInvestments() {
        genericApi('investments')
            .getAll()
            .then((res) => {
                setInvestments(res);
            });
    }

    const handleClickOpen = () => {
        console.log(info);
        getCompanies();
        getInvestments();
        setValues(info);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        props.handleSeeking(values);
        handleClose();
    };

    //Handles majority of form elements
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    //Handles autocomplete tabs
    const handleAuto = (toAdd) => {
        //Combine the old list of info with the new information
        setValues({
            ...values,
            ...toAdd
        });
    };

    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
            >
                {type == 'create' ? 'Create' : 'Edit'}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <form autoComplete="off" noValidate>
                    <Card>
                        <CardHeader
                            subheader="Enter Investment the company holds"
                            title="Current Investments Form"
                        />
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3}>
                                {type !=
                                    'edit' /** We don't want to be able to edit the companies/investment as this changes primary key */ && (
                                    <>
                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                fullWidth
                                                id="company"
                                                label="Select Company"
                                                options={companies}
                                                defaultValue={initialCompany}
                                                getOptionLabel={(company) =>
                                                    company.name +
                                                    '   (' +
                                                    company.region +
                                                    ')'
                                                }
                                                onChange={(event, value) => {
                                                    if (value != null) {
                                                        console.log(value);
                                                        handleAuto({
                                                            company: value.name,
                                                            companies_id:
                                                                value.id
                                                        });
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Company"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Autocomplete
                                                fullWidth
                                                id="investment"
                                                label="Select Investment"
                                                options={investments}
                                                defaultValue={initialInvestment}
                                                getOptionLabel={(investment) =>
                                                    investment.name
                                                }
                                                onChange={(event, value) => {
                                                    if (value != null) {
                                                        console.log(value);
                                                        handleAuto({
                                                            investment:
                                                                value.name,
                                                            investments_id:
                                                                value.id
                                                        });
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Investment"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item md={6} xs={12}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={auLocale}
                                    >
                                        <DatePicker
                                            label="Date Info Entered"
                                            name="Date Entered"
                                            value={values.date_entered}
                                            onChange={(newValue) => {
                                                setValues({
                                                    ...values,
                                                    date_entered:
                                                        newValue.toDateString()
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Amount"
                                        name="amount"
                                        onChange={handleChange}
                                        value={values.amount}
                                        variant="outlined"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Comments"
                                        name="description"
                                        onChange={handleChange}
                                        value={values.description}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </form>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Save Details
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
