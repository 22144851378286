/*response.js - Middleware to handle response parsing, error handling, logging, etc...*/

/*
This is kept separate to keep our files lean and allow a clean separation for any response and error logic you may want to handle here for all API calls. Maybe you want to log an error here or create custom actions for authorization based on the response header.
*/

export function handleResponse(response) {
    if (response.results) {
        return response.results;
    }

    if (response.data) {
        return response.data;
    }

    return response;
}

export function handleError(error) {
    //Causes error message to pop up as alert
    /*if (error.response.status == 401){
      window.location.replace('/cpd/login');
    }*/

    try {
        if (error.response.data) {
            alert(
                'Message:  ' +
                    error.response.data.message +
                    '\n\nDetails:  ' +
                    error.response.data.details +
                    '\n\nHint:  ' +
                    error.response.data.hint
            );
        } else {
            alert(JSON.stringify(error.response));
            return error.response;
        }
    } catch (error) {
        console.log('Error handling failed');
    }

    return error;
}
