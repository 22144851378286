import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography,
    Button
} from '@material-ui/core';

//For api
import HandleInvestment from './HandleInvestment';

const InvestmentShow = (props) => {
    const investment = props.investment;
    const handleInvestment = props.handleInvestment;
    return (
        <Card>
            <CardHeader
                title={investment.name}
                action={
                    <>
                        <HandleInvestment
                            type="edit"
                            handleInvestment={handleInvestment}
                            info={investment}
                        ></HandleInvestment>
                        <Button
                            variant="contained"
                            onClick={props.generateExcel}
                        >
                            Download
                        </Button>
                    </>
                }
                titleTypographyProps={{ variant: 'h2' }}
                subheader={
                    investment.target_returns +
                    ' | ' +
                    investment.sector +
                    ' | ' +
                    investment.deal_type
                }
            />
            <Divider />
            <CardContent>
                <Typography component={'div'}>
                    <Grid container>
                        <Grid item xs={12}>
                            <p>{investment.location}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p>{investment.description}</p>
                        </Grid>
                        <Grid item xs={12}>
                            <p>{/*investment.reports*/}</p>
                        </Grid>
                    </Grid>
                </Typography>
            </CardContent>
            <Divider />
        </Card>
    );
};

export default InvestmentShow;
