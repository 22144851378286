import { ApiCore } from './utilities/api-core';
// Task API

const url = 'people';
const plural = 'people';
const single = 'people';

// plural and single may be used for message logic if needed in the ApiCore class.

/**
 *
 * @param {*} url The only essential, specifies table e.g. 'people'
 * @param {*} plural
 * @param {*} single
 * @returns axios api
 */
function genericApi(url, plural = url, single = url) {
    const genericApi = new ApiCore({
        getAll: true,
        getSingle: true,
        post: true,
        put: true,
        patch: true,
        remove: true,
        complexRemove: true,
        complexGetSingle: true,
        complexPutSingle: true,
        url: url,
        plural: plural,
        single: single
    });
    return genericApi;
}

export default genericApi;
