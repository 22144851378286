import React, { useState } from 'react';
import { supabase } from '../utils/supabaseClient';
import {
    TextField,
    Typography,
    Box,
    Button,
    Container
} from '@material-ui/core';

export default function ResetPassword() {
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const handleResetPass = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const { error } = await supabase.auth.updateUser({
            password
        });
        if (error) {
            alert(error.message);
        } else {
            alert('Password has been reset!');
        }
        setLoading(false);
    };

    return (
        <Container maxWidth="sm">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {/* Enter new password */}
                </Typography>
                <form noValidate autoComplete="off" onSubmit={handleResetPass}>
                    <TextField
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Loading ...' : 'Reset Password'}
                    </Button>
                </form>
            </Box>
        </Container>
    );
}
