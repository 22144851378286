//! Only difference between this and edit will be that edit has values passed and uses patch in axios
import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

//Test
import CompanyForm from './CompanyForm';
import genericApi from '../../../apis/genericApi';

const initialValue = {
    description: '',
    location: '',
    name: '',
    other: '',
    size: -1,
    specialty: ''
    //If this was a relationship we could have InvestmendId which would display dropdown to choose from
};

//Have companytype and lookingfor in individual forms

export default function FormDialog({ company, setCompany }) {
    console.log(company);
    const [open, setOpen] = useState(false);
    //Values lifted from form
    //const [company, setCompany] = React.useState(initialValue);

    //useEffect - hooks don't set state instantly - it's async, so we need to update render if state changes
    //When company changes, this changes
    /*useEffect(() => {
      setCompany(companyInfo)
      console.log(companyInfo)
      console.log(company)
    },
    //If companyInfo changes it's updated
    [companyInfo]
  );*/
    //useState(initialState) (or whatever you call it) will only use initialState on the first render.
    const [localCompany, setLocalCompany] = useState(company);
    //To make changes reflected everytime value changes,register an effect on the input prop
    useEffect(() => {
        setLocalCompany(company);
    }, [company]);

    const handleChange = (event) => {
        console.log('Lifted');
        console.log(event.target.value);
        setLocalCompany({
            ...localCompany,
            [event.target.name]: event.target.value
        });
        // setCompany({
        //     ...company,
        //     [event.target.name]: event.target.value
        // });
        //If i figure out how to update everything use this:
        //setCompany(event)
    };

    //Handles autocomplete tabs
    const handleAuto = (toAdd) => {
        setCompany({
            ...company,
            ...toAdd
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        //setCompany(companyInfo)
        setOpen(false);
    };

    const handleSubmit = () => {
        console.log('submitted');
        console.log(company);
        try {
            genericApi('companies')
                .patch(localCompany, localCompany.id)
                .then((res) => {
                    console.log('Updated', res);
                });
        } catch (err) {
            console.log('Error editing company' + err);
        }
        setCompany(localCompany);
        handleClose();
    };

    return (
        <Fragment>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
            >
                Edit
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {/**Pass this props? Then it can be the same for edit and delete */}
                <CompanyForm
                    handleChange={handleChange}
                    handleAuto={handleAuto}
                    values={localCompany}
                />
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        variant="contained"
                    >
                        Save details
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
