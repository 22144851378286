import { useState, useEffect } from 'react';
import { Box, Container } from '@material-ui/core';

import genericApi from '../apis/genericApi';
import { useParams } from 'react-router-dom';
import MeetingsList from './MeetingsList';
import PersonShow from '../components/customer/single/PersonShow';
import { supabase } from '../utils/supabaseClient';

const Person = () => {
    const [person, setPerson] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getPerson(id);
    }, []);

    ///cpd/people/${id}/show

    function removeNulls(o) {
        //Function to remove null values from api object.
        //Needed for input form to not throw errors.
        //! We should abstract this, but really it is only needed for edit. (i.e individual components)
        for (var key in o) {
            if (null === o[key]) o[key] = '';
        }
        return o;
    }

    function getPerson() {
        genericApi('people')
            .getSingle(id)
            .then((res) => {
                console.log(res[0]);
                //let arr = _parseTasks(res);
                setPerson(removeNulls(res[0]));
            });
    }

    /*
  peopleApi.patch(newPerson,newPerson.id).then((res) => {
    setPerson(
      newPerson
    );
});*/

    function editPerson(newPerson) {
        //For info on this refer to peopleList implementation
        const people = newPerson;
        const employsKeys = ['companies_id', 'people_id'];
        const employs = {};

        employsKeys.forEach(function (key, index) {
            console.log(key);
            employs[key] = people[key];
            delete people[key];
        });

        genericApi('people')
            .patch(newPerson, newPerson.id)
            .then((res) => {
                employs.people_id = newPerson.id;

                //employs?companies_id=eq.7&people_id=eq.101
                const complexQuery = `employs?companies_id=eq.${employs.companies_id}&people_id=eq.${employs.people_id}`;

                console.log(employs);
                if (
                    employs.companies_id != -1 &&
                    employs.companies_id != null
                ) {
                    //! Warning this does not delete old company, just adds a new one - we could actually use this by adding a time added field. This would show someones progression through career
                    // genericApi('employs')
                    //     .complexPutSingle(complexQuery, employs)
                    //     .then((res) => {
                    //         console.log(res);
                    //     });

                    // Update old entry with new one
                    supabase
                        .from('employs')
                        .update(employs)
                        .match({ people_id: employs.people_id })
                        .then((res) => {
                            console.log(res);
                        });
                }

                setPerson(newPerson);
            });
    }

    return (
        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 3
            }}
        >
            <Container maxWidth="lg">
                <PersonShow person={person} handlePerson={editPerson} />
                <MeetingsList type="specified" id={id}></MeetingsList>
            </Container>
        </Box>
    );
};

export default Person;
